import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _575a6114 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _60f8f7ac = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _16450f6b = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _7ddd630e = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _218e44d1 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _07e5f7c2 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _1a2b2624 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _3b2b60f0 = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _5b09d7ab = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _6c52474f = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _032b98a9 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _6f193618 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _5eae1d1e = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _0155f991 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _c2898616 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _0ad729d0 = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _473b124c = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _669cedd0 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _3d75de4c = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _02972aab = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _575a6114,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _60f8f7ac,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _16450f6b,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _7ddd630e,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _218e44d1,
    name: "receipt"
  }, {
    path: "/search",
    component: _07e5f7c2,
    name: "search"
  }, {
    path: "/search-booking",
    component: _1a2b2624,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _3b2b60f0,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _5b09d7ab,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _6c52474f,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _032b98a9,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _6f193618,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _5eae1d1e,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _0155f991,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _c2898616,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _0ad729d0,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _473b124c,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _669cedd0,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _3d75de4c,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _02972aab,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
